import React from "react";
import styles from "./legal.module.css";
import { Link } from "react-router-dom";

function Legal() {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontFamily: "TelegraphLight",
        flexDirection: "column",
        color: "white",
      }}
    >
      <div className={styles.LogoContainer}>
        <img src="/images/headerLogo.svg" />
      </div>
      <div
        className={styles.legalContainer}
        style={{
          width: "80%",
          display: "flex",
          flexDirection: "column",
          color: "#FFF !important",
          fontWeight: "600",
          //   textAlign: "left",
        }}
      >
        <div>
          <p style={{ textAlign: "center" }}>
            <strong>
              <span style={{ fontSize: "15pt" }}>
                Terms of Services (“ToS”)
              </span>
            </strong>
          </p>
          <ol>
            <li style={{ listStyleType: "decimal", fontSize: "11pt" }}>
              <p style={{ textAlign: "justify" }}>
                <span style={{ fontSize: "11pt" }}>Acceptance&nbsp;</span>
              </p>
            </li>
            <li style={{ listStyleType: "decimal", fontSize: "11pt" }}>
              <p style={{ textAlign: "justify" }}>
                <span style={{ fontSize: "11pt" }}>Eligibility</span>
              </p>
            </li>
            <li style={{ listStyleType: "decimal", fontSize: "11pt" }}>
              <p style={{ textAlign: "justify" }}>
                <span style={{ fontSize: "11pt" }}>Compliance</span>
              </p>
            </li>
            <li style={{ listStyleType: "decimal", fontSize: "11pt" }}>
              <p style={{ textAlign: "justify" }}>
                <span style={{ fontSize: "11pt" }}>
                  Description of our Project and Features&nbsp;
                </span>
              </p>
            </li>
            <li style={{ listStyleType: "decimal", fontSize: "11pt" }}>
              <p style={{ textAlign: "justify" }}>
                <span style={{ fontSize: "11pt" }}>Native Token&nbsp;</span>
              </p>
            </li>
            <li style={{ listStyleType: "decimal", fontSize: "11pt" }}>
              <p style={{ textAlign: "justify" }}>
                <span style={{ fontSize: "11pt" }}>Proprietary rights</span>
              </p>
            </li>
            <li style={{ listStyleType: "decimal", fontSize: "11pt" }}>
              <p style={{ textAlign: "justify" }}>
                <span style={{ fontSize: "11pt" }}>Warranty disclaimer</span>
              </p>
            </li>
            <li style={{ listStyleType: "decimal", fontSize: "11pt" }}>
              <p style={{ textAlign: "justify" }}>
                <span style={{ fontSize: "11pt" }}>
                  Limitation of Liability
                </span>
              </p>
            </li>
            <li style={{ listStyleType: "decimal", fontSize: "11pt" }}>
              <p style={{ textAlign: "justify" }}>
                <span style={{ fontSize: "11pt" }}>Other</span>
              </p>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              Please read these Terms carefully before using the&nbsp;
            </span>
            <a href="" style={{ color: "white" }}>
              <u>
                <span style={{ color: "white", fontSize: "11pt" }}>
                  
                </span>
              </u>
            </a>
            <span style={{ fontSize: "11pt" }}>
              &nbsp;website, our services/products. If you do not agree with
              these Terms or any parts, then you are prohibited and must refrain
              from using the Site.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              These Terms set forth the legally binding terms and conditions for
              this website (“Site”) and/or services, features, content or
              applications offered by Ÿ (“Project” or “we”).
            </span>
          </p>
          <p>
            <br />
          </p>
          <ol>
            <li style={{ listStyleType: "decimal", fontSize: "13pt" }}>
              <p style={{ textAlign: "center" }}>
                <span style={{ fontSize: "13pt" }}>Acceptance&nbsp;</span>
              </p>
            </li>
          </ol>
          <p style={{ textAlign: "justify" }}>
            <span
              style={{
                color: "#ffffff",
                fontSize: "11pt",
              }}
            >
              By using our website, acquiring tokens, engaging in Project
              activities, you agree to abide by these Terms, along with any
              additional policies and procedures posted on the Site or our
              official channels. These terms may be updated periodically without
              prior notice to you.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              These Terms apply to all users, including, without limitation,
              users who are contributors of content, information, and other
              materials or services, registered or otherwise.
            </span>
          </p>
          <p>
            <br />
          </p>
          <ol start={2}>
            <li style={{ listStyleType: "decimal", fontSize: "13pt" }}>
              <p style={{ textAlign: "center" }}>
                <span style={{ fontSize: "13pt" }}>Eligibility&nbsp;</span>
              </p>
            </li>
          </ol>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              You represent and warrant that you are at least 18 years of age.
              If you are under age 18, you may not, under any circumstances or
              for any reason, participate in any activities related to the
              Project. You are solely responsible for ensuring that these Terms
              are in compliance with all laws, Terms and regulations applicable
              to you.&nbsp;
            </span>
          </p>
          <p>
            <br />
          </p>
          <ol start={3}>
            <li style={{ listStyleType: "decimal", fontSize: "13pt" }}>
              <p style={{ textAlign: "center" }}>
                <span style={{ fontSize: "13pt" }}>Compliance</span>
                <strong>
                  <span style={{ fontSize: "13pt" }}>&nbsp;</span>
                </strong>
              </p>
            </li>
          </ol>
          <p style={{ textAlign: "justify" }}>
            <span
              style={{
                color: "#ffffff",
                fontSize: "11pt",
              }}
            >
              The Project is restricted to users in specific jurisdictions as
              permitted by law.&nbsp;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span
              style={{
                color: "#ffffff",
                fontSize: "11pt",
              }}
            >
              By participating, you confirm compliance with all applicable
              laws.&nbsp;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span
              style={{
                color: "#ffffff",
                fontSize: "11pt",
              }}
            >
              You assert that you are not:
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span
              style={{
                color: "#ffffff",
                fontSize: "11pt",
              }}
            >
              a) in a country under the U.S. Government embargo&nbsp;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span
              style={{
                color: "#ffffff",
                fontSize: "11pt",
              }}
            >
              b) not listed in any U.S. Government restricted parties list
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span
              style={{
                color: "#ffffff",
                fontSize: "11pt",
              }}
            >
              c) you are not a resident or citizen of forbidden locations.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span
              style={{
                color: "#ffffff",
                fontSize: "11pt",
              }}
            >
              Prohibited actions include using software such as VPN or
              techniques to alter your IP address.&nbsp;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              We reserve the right, but have no obligation, to monitor where our
              Site is accessed from. Furthermore, we reserve the right, at any
              time, in our sole discretion, to block access to the Project and
              its features, in whole or in part, from any geographic location,
              IP addresses and unique device identifiers or to any user who we
              believe is in breach of these Rules.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ color: "#0d0d0d", fontSize: "11pt" }}>
              Forbidden locations: USA, UAE, China.
            </span>
          </p>
          <p>
            <br />
          </p>
          <ol start={4}>
            <li style={{ listStyleType: "decimal", fontSize: "13pt" }}>
              <p style={{ textAlign: "center" }}>
                <span style={{ fontSize: "13pt" }}>
                  Description of our Project and Features&nbsp;
                </span>
              </p>
            </li>
          </ol>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              Ÿ is a revolutionary service that aims to predict the future of
              financial markets by leveraging cutting-edge artificial
              intelligence technology.&nbsp;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              At its core, Ÿ builds upon the premise that markets are only as
              efficient as the supply and demand providers shaping them. By
              processing available data quicker and more efficiently than other
              market participants, Ÿ seeks to identify and predict these less
              efficient markets. However, accomplishing this efficiently in
              practice remains a significant challenge.
            </span>
          </p>
          <ul>
            <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
              <p style={{ textAlign: "justify" }}>
                <strong>
                  <span style={{ fontSize: "11pt" }}>Ÿ Oracle,</span>
                </strong>
                <span style={{ fontSize: "11pt" }}>
                  &nbsp;an advanced and informed AI-powered Market Data Oracle.
                  The Oracle comprises multiple tightly integrated components,
                  including Observers and Predictors, which collaborate to
                  deliver optimal precision in market predictions. Observers
                  relentlessly monitor diverse information sources 24/7, while
                  Predictors analyse, label, and score incoming data to forecast
                  future outcomes.
                </span>
              </p>
            </li>
          </ul>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              The Oracle is designed for seamless integration across multiple
              output channels, including real-time updates and reports via Ÿ
              Telegram bot and channel. Future development plans include browser
              dashboards for in-depth analysis, developer-centric APIs, and
              WebSockets, empowering users to interact with the Oracle in ways
              that best suit their needs.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p style={{ textAlign: "justify" }}>
            <em>
              <span
                style={{
                  color: "#ffffff",
                  fontSize: "11pt",
                }}
              >
                ! Although our team is fervently committed to delivering, please
                notice that the pace of implementing new features and updates
                hinges on market anticipation and the level of funds accumulated
                in our treasury.
              </span>
            </em>
          </p>
          <p>
            <br />
          </p>
          <ol start={5}>
            <li style={{ listStyleType: "decimal", fontSize: "13pt" }}>
              <p style={{ textAlign: "center" }}>
                <span style={{ fontSize: "13pt" }}>Native token</span>
              </p>
            </li>
          </ol>
          <p style={{ textAlign: "justify" }}>
            <strong>
              <span style={{ fontSize: "11pt" }}>Native token</span>
            </strong>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              Project native ecosystem token is $YAI (“Token”) and is available
              for trading on Uniswap.&nbsp;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              For tokenomics and financial plan check our Gitbook&nbsp;
            </span>
            <a href="">
              <u>
                <span style={{ color: "#1155cc", fontSize: "11pt" }}>
                  
                </span>
              </u>
            </a>
            <span style={{ fontSize: "11pt" }}>&nbsp;.&nbsp;</span>
          </p>
          <p style={{ textAlign: "center" }}>
            <em>
              <span style={{ fontSize: "11pt" }}>Token disclaimer</span>
            </em>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              In no case Ÿ´s Team does not provide you with recommendations to
              buy cryptocurrencies, sell or hold them.&nbsp;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              The $YAI token is a digital asset subject to inherent risks
              similar to other cryptocurrencies. These risks encompass, but are
              not limited to, potential loss of value or complete
              investment.&nbsp;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              The value of the $YAI token can exhibit significant volatility and
              unpredictability, potentially resulting in substantial financial
              losses. By engaging in the purchase, holding, or trading of $YAI
              tokens, you explicitly acknowledge and assume these risks.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              The $YAI team does not partake in undisclosed transactions
              involving $YAI tokens in any capacity. Consequently, we bear no
              responsibility for any gains or losses incurred as a result of
              your distribution of $YAI tokens. We provide no guarantee
              regarding the liquidity of $YAI tokens as well as we do not bear
              any responsibility for third parties errors that can lead to
              significant losses in token value and disclaim any liability for
              losses or damages arising from liquidity deficiencies or the
              inability to buy or sell your tokens.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              Prior to deciding to acquire $YAI tokens, we strongly advise
              thorough consideration of potential risks, including consultation
              with legal and financial advisors if necessary. Your purchase,
              holding, or trading of $YAI tokens signifies your comprehension
              and acceptance of this disclaimer.&nbsp;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              $YAI is a utility token with strong implementation of usage within
              the ecosystem.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <strong>
              <span style={{ fontSize: "11pt" }}>Prohibition of Bot Usage</span>
            </strong>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              - &nbsp;You are strictly prohibited from using any kind of
              automated trading systems, bots including but not limited to
              sniper bots or other software or technology that can manipulate
              the price, exploit vulnerabilities, or unfairly benefit from the
              project token.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              - You shall not engage in any activity that disrupts or
              compromises the integrity of the project token, the project
              ecosystem, or the platform on which the project token is hosted.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              Violation of the bot usage restriction may result in consequences
              such as freezing or confiscation of project tokens, suspension of
              access to the Services. The project reserves the right to
              investigate any suspicious or fraudulent activities related to the
              project token and take appropriate action based on its findings.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <strong>
              <span style={{ fontSize: "11pt" }}>Price Manipulation</span>
            </strong>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              - &nbsp;You shall not engage in any activity that artificially
              manipulates the price or market conditions of the project token.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              - You shall not engage in pump-and-dump schemes, wash trading,
              front-running, sniping, or any other manipulative practices that
              can create a false or misleading market for the project token.
            </span>
          </p>
          <p>
            <br />
          </p>
          <ol start={6}>
            <li style={{ listStyleType: "decimal", fontSize: "13pt" }}>
              <p style={{ textAlign: "center" }}>
                <span style={{ fontSize: "13pt" }}>
                  Proprietary rights&nbsp;
                </span>
              </p>
            </li>
          </ol>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              All intellectual property related to and all rights, title, and
              interest in and to the Site, Services, modifications, and
              derivative works thereof, as between you and us, shall remain the
              sole property of the Project. You shall not take any action that
              would violate, jeopardise or impair such intellectual property or
              ownership rights, or the legality and/or enforceability thereof.
              The custom models, and AI-optimised data feeds,any design,
              technology and other related intellectual property are protected
              under copyright, trademark and other intellectual property laws
              and other applicable law.&nbsp;
            </span>
          </p>
          <p>
            <br />
          </p>
          <ol start={7}>
            <li style={{ listStyleType: "decimal", fontSize: "13pt" }}>
              <p style={{ textAlign: "center" }}>
                <span style={{ fontSize: "13pt" }}>
                  Warranty disclaimer&nbsp;
                </span>
              </p>
            </li>
          </ol>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              We have no special relationship with or fiduciary duty to you. You
              acknowledge that we have no duty to take any action regarding:
            </span>
          </p>
          <ol>
            <li style={{ listStyleType: "lower-alpha", fontSize: "11pt" }}>
              <p style={{ textAlign: "justify" }}>
                <span style={{ fontSize: "11pt" }}>your trading results;</span>
              </p>
            </li>
            <li style={{ listStyleType: "lower-alpha", fontSize: "11pt" }}>
              <p style={{ textAlign: "justify" }}>
                <span style={{ fontSize: "11pt" }}>
                  &nbsp;what Content you access via the Site; or
                </span>
              </p>
            </li>
            <li style={{ listStyleType: "lower-alpha", fontSize: "11pt" }}>
              <p style={{ textAlign: "justify" }}>
                <span style={{ fontSize: "11pt" }}>
                  &nbsp;how you may interpret or use the Content.
                </span>
              </p>
            </li>
          </ol>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              You release us from all liability for you having acquired or not
              acquired Content through the Site. We make no representations
              concerning any Content contained in or accessed through the Site,
              and we will not be responsible or liable for the accuracy,
              copyright compliance, or legality of material or Content contained
              in or accessed through the Site.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              You acknowledge that: (a) none of the information contained on the
              Site or made available through the Site constitutes a
              solicitation, offer, opinion, endorsement, or recommendation by
              the Project to buy, hold or sell any security or to provide legal,
              tax, accounting, or investment advice or services, regarding the
              profitability or suitability of any security or investment; and
              (b) the information provided on the Site is not intended for use
              by, or distribution to, any person or entity in any jurisdiction
              or country where such use or distribution would be contrary to law
              or regulation.
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              THE SERVICES AND CONTENT ARE PROVIDED “AS IS”, “AS AVAILABLE” AND
              WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING, BUT
              NOT LIMITED TO, THE IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT,
              MERCHANTABILITY FOR A PARTICULAR PURPOSE, BY ANY COURSE OF
              PERFORMANCE, ALL OF WHICH ARE EXPRESSLY DISCLAIMED. WE, AND OUR
              DIRECTORS, EMPLOYEES, AGENTS, SUPPLIERS, PARTNERS AND CONTENT
              PROVIDERS DO NOT WARRANT THAT: (I) THE PROJECT WILL BE SECURE OR
              AVAILABLE AT ANY PARTICULAR TIME OR LOCATION; (II) ANY DEFECTS OR
              ERRORS WILL BE CORRECTED; (III) ANY CONTENT OR THIRD PARTY LINKS
              AVAILABLE AT OR THROUGH THE CONTENT IS FREE OF VIRUSES OR OTHER
              HARMFUL COMPONENTS; OR (IV) THE RESULTS OF PARTICIPATING IN
              PROJECT ACTIVITIES WILL MEET YOUR REQUIREMENTS.&nbsp;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              YOUR USE OF THE SERVICES IS SOLELY AT YOUR OWN RISK. PLEASE, BE
              RESPONSIBLE!
            </span>
          </p>
          <p>
            <br />
          </p>
          <ol start={8}>
            <li style={{ listStyleType: "decimal", fontSize: "13pt" }}>
              <p style={{ textAlign: "center" }}>
                <span style={{ fontSize: "13pt" }}>
                  Limitation of Liability&nbsp;
                </span>
              </p>
            </li>
          </ol>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              IN NO EVENT SHALL WE, NOR OUR DIRECTORS, EMPLOYEES, AGENTS,
              MANAGERS, MEMBERS, PRINCIPALS, PARTNERS, SUPPLIERS OR CONTENT
              PROVIDERS (“RELATED PARTIES”), BE LIABLE UNDER CONTRACT, TORT,
              STRICT LIABILITY, NEGLIGENCE OR ANY OTHER LEGAL OR EQUITABLE
              THEORY WITH RESPECT TO THE PROJECT FOR ANY LOST PROFITS, DATA
              LOSS, COST OF PROCUREMENT OF SUBSTITUTE GOODS, TECHNOLOGY, RIGHTS
              OR SERVICES, OR SPECIAL, INDIRECT, INCIDENTAL, PUNITIVE,
              COMPENSATORY OR CONSEQUENTIAL DAMAGES OF ANY KIND WHATSOEVER.
            </span>
          </p>
          <p>
            <br />
          </p>
          <ol start={9}>
            <li style={{ listStyleType: "decimal", fontSize: "13pt" }}>
              <p style={{ textAlign: "center" }}>
                <span style={{ fontSize: "13pt" }}>Other&nbsp;</span>
              </p>
            </li>
          </ol>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              We reserve the right, in our sole discretion, to modify or replace
              any of these Terms, or change, suspend, or discontinue the Site
              (including without limitation, the availability of any feature,
              database, or content) at any time by posting a notice on the Site.
              We may also impose limits on certain features and services or
              restrict your access to parts or all of the Site without notice or
              liability.&nbsp;
            </span>
          </p>
          <p>
            <br />
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontSize: "11pt" }}>
              Trading contains substantial risk and is not for every investor.
              An investor could potentially lose all or more than the initial
              investment. Risk capital is money that can be lost without
              jeopardising one’s financial security or lifestyle. Only risk
              capital should be used for trading and only those with sufficient
              risk capital should consider trading. Past performance is not
              necessarily indicative of future results.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <br />
          </p>
          <p>
            <br />
          </p>
          <p>
            <br />
          </p>
          <p>
            <br />
          </p>
          <p>
            <br />
          </p>
          <p>
            <br />
          </p>
          <p>
            <br />
          </p>
          <p>
            <br />
          </p>
        </div>

        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            color: "#FFF",
            flexDirection: "column",
            rowGap: "30px",
            marginBottom: "50px",
          }}
        >
          <div className={styles.LogoContainer}>
            <img src="/images/headerLogo.svg" />
          </div>

          <div
            style={{
              color: "#FFF",
              textDecoration: "none",
              display: "flex",
              columnGap: "15px",
            }}
          >
            <Link to="https://twitter.com/yai_erc" className={styles.linkItem}>
              Twitter
            </Link>
            <Link to="https://t.me/yAIoracle" className={styles.linkItem}>
              Telegram
            </Link>
            <Link to="" className={styles.linkItem}>
              Documentation
            </Link>
            <Link to="/legal" className={styles.linkItem}>
              Legal
            </Link>
            <Link to="" className={styles.linkItem}>
              API
            </Link>
          </div>
          <div className={styles.contractAddress}>
            CA: 0x477a3d269266994F15E9C43A8D9C0561C4928088
          </div>
          <div className={styles.copyright}>
            Copyright © Y 2024. All rights reserved
          </div>
        </div>
      </div>
    </div>
  );
}

export default Legal;
