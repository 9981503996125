import React, { useState, useEffect } from "react";
import styles from "./Scroll.module.css";
import DisclaimerModal from "../DisclaimerModal/DisclaimerModal";
// import { useNavigate } from "react-router-dom";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useDisclosure } from "@chakra-ui/react";
function Scroll() {
  const [showModal, setShowModal] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);

  const navigate = useNavigate();

  const location = useLocation();

  //   const handleLearnMoreClick = () => {
  //     // setShowModal(true);
  //     isOpenDisclaimerModal();
  //     navigate("/disclaimer_trading");
  //   };

  //   useEffect(() => {
  //     if (location.pathname === "/disclaimer_trading") {
  //       onOpenDisclaimerModal();
  //     } else {
  //       onCloseDisclaimerModal();
  //     }
  //   }, [location.pathname, onOpenDisclaimerModal, onCloseDisclaimerModal]);

  const handleLearnMoreClick = () => {
    // onOpenDisclaimerModal();
    navigate("/disclaimer_trading");
  };

  //   const handleLearnMoreClick = async () => {
  //     if (!isOpenDisclaimerModal) {
  //       onOpenDisclaimerModal();
  //     }
  //     await navigate("/disclaimer_trading");
  //     //   await navigate("/disclaimer_trading", { state: { openModal: true } });

  //   };

  const handleCloseModal = async () => {
    // setShowModal(false, () => {
    //   navigate("/");
    // });
    // console.log("closing from scroll");
    // onCloseDisclaimerModal();
    // await navigate("/");
  };

  return (
    <div className={styles.slideTextContainer}>
      <div className={styles.fixedText} onClick={handleLearnMoreClick}>
        Learn More 
      </div>
      {/* <Routes> */}
      {/* <Route
          element={
            <DisclaimerModal
              isOpen={isOpenDisclaimerModal}
              onClose={handleCloseModal}
              onOpen={onOpenDisclaimerModal}
            />
          }
          path="/disclaimer_trading"
        />
        {/* <Route path="/" element={<Home/>} /> */}
      {/* </Routes> */}
      {/* {isOpenDisclaimerModal && ( */}
      {/* <DisclaimerModal
          isOpen={isOpenDisclaimerModal}
          onClose={handleCloseModal}
          onOpen={onOpenDisclaimerModal}
        />  */}
      {/* )} */}

      <div className={styles.slideText}>
        {" "}
        <div className={styles.scroll}>
          {/* <div className={styles.Mscroll}> */}
          <span className={styles.runningLine}>
            Pursuant to global financial regulations, the content provided on
            this website serves solely for general informational purposes. Trade
            only funds you are willing to risk losing entirely. Cryptoassets
            pose high risks, and there may be no recourse available in case of
            adverse trade outcomes.&nbsp;
          </span>
          <span className={styles.runningLine}>
            Pursuant to global financial regulations, the content provided on
            this website serves solely for general informational purposes. Trade
            only funds you are willing to risk losing entirely. Cryptoassets
            pose high risks, and there may be no recourse available in case of
            adverse trade outcomes.&nbsp;
          </span>
          <span className={styles.runningLine}>
            Pursuant to global financial regulations, the content provided on
            this website serves solely for general informational purposes. Trade
            only funds you are willing to risk losing entirely. Cryptoassets
            pose high risks, and there may be no recourse available in case of
            adverse trade outcomes.&nbsp;
          </span>
          <span className={styles.runningLine}>
            Pursuant to global financial regulations, the content provided on
            this website serves solely for general informational purposes. Trade
            only funds you are willing to risk losing entirely. Cryptoassets
            pose high risks, and there may be no recourse available in case of
            adverse trade outcomes. &nbsp;
          </span>
          <span className={styles.runningLine}>
            Pursuant to global financial regulations, the content provided on
            this website serves solely for general informational purposes. Trade
            only funds you are willing to risk losing entirely. Cryptoassets
            pose high risks, and there may be no recourse available in case of
            adverse trade outcomes. &nbsp;
          </span>
          <span className={styles.runningLine}>
            Pursuant to global financial regulations, the content provided on
            this website serves solely for general informational purposes. Trade
            only funds you are willing to risk losing entirely. Cryptoassets
            pose high risks, and there may be no recourse available in case of
            adverse trade outcomes. &nbsp;
          </span>
          <span className={styles.runningLine}>
            Pursuant to global financial regulations, the content provided on
            this website serves solely for general informational purposes. Trade
            only funds you are willing to risk losing entirely. Cryptoassets
            pose high risks, and there may be no recourse available in case of
            adverse trade outcomes. &nbsp;
          </span>
          <span className={styles.runningLine}>
            Pursuant to global financial regulations, the content provided on
            this website serves solely for general informational purposes. Trade
            only funds you are willing to risk losing entirely. Cryptoassets
            pose high risks, and there may be no recourse available in case of
            adverse trade outcomes. &nbsp;
          </span>
          <span className={styles.runningLine}>
            Pursuant to global financial regulations, the content provided on
            this website serves solely for general informational purposes. Trade
            only funds you are willing to risk losing entirely. Cryptoassets
            pose high risks, and there may be no recourse available in case of
            adverse trade outcomes. &nbsp;
          </span>
          <span className={styles.runningLine}>
            Pursuant to global financial regulations, the content provided on
            this website serves solely for general informational purposes. Trade
            only funds you are willing to risk losing entirely. Cryptoassets
            pose high risks, and there may be no recourse available in case of
            adverse trade outcomes. &nbsp;
          </span>
          <span className={styles.runningLine}>
            Pursuant to global financial regulations, the content provided on
            this website serves solely for general informational purposes. Trade
            only funds you are willing to risk losing entirely. Cryptoassets
            pose high risks, and there may be no recourse available in case of
            adverse trade outcomes. &nbsp;
          </span>
          <span className={styles.runningLine}>
            Pursuant to global financial regulations, the content provided on
            this website serves solely for general informational purposes. Trade
            only funds you are willing to risk losing entirely. Cryptoassets
            pose high risks, and there may be no recourse available in case of
            adverse trade outcomes. &nbsp;
          </span>
          <span className={styles.runningLine}>
            Pursuant to global financial regulations, the content provided on
            this website serves solely for general informational purposes. Trade
            only funds you are willing to risk losing entirely. Cryptoassets
            pose high risks, and there may be no recourse available in case of
            adverse trade outcomes. &nbsp;
          </span>
          <span className={styles.runningLine}>
            Pursuant to global financial regulations, the content provided on
            this website serves solely for general informational purposes. Trade
            only funds you are willing to risk losing entirely. Cryptoassets
            pose high risks, and there may be no recourse available in case of
            adverse trade outcomes. &nbsp;
          </span>
          <span className={styles.runningLine}>
            Pursuant to global financial regulations, the content provided on
            this website serves solely for general informational purposes. Trade
            only funds you are willing to risk losing entirely. Cryptoassets
            pose high risks, and there may be no recourse available in case of
            adverse trade outcomes. &nbsp;
          </span>
          <span className={styles.runningLine}>
            Pursuant to global financial regulations, the content provided on
            this website serves solely for general informational purposes. Trade
            only funds you are willing to risk losing entirely. Cryptoassets
            pose high risks, and there may be no recourse available in case of
            adverse trade outcomes. &nbsp;
          </span>
          <span className={styles.runningLine}>
            Pursuant to global financial regulations, the content provided on
            this website serves solely for general informational purposes. Trade
            only funds you are willing to risk losing entirely. Cryptoassets
            pose high risks, and there may be no recourse available in case of
            adverse trade outcomes. &nbsp;
          </span>
          <span className={styles.runningLine}>
            Pursuant to global financial regulations, the content provided on
            this website serves solely for general informational purposes. Trade
            only funds you are willing to risk losing entirely. Cryptoassets
            pose high risks, and there may be no recourse available in case of
            adverse trade outcomes. &nbsp;
          </span>
          <span className={styles.runningLine}>
            Pursuant to global financial regulations, the content provided on
            this website serves solely for general informational purposes. Trade
            only funds you are willing to risk losing entirely. Cryptoassets
            pose high risks, and there may be no recourse available in case of
            adverse trade outcomes. &nbsp;
          </span>
          <span className={styles.runningLine}>
            Pursuant to global financial regulations, the content provided on
            this website serves solely for general informational purposes. Trade
            only funds you are willing to risk losing entirely. Cryptoassets
            pose high risks, and there may be no recourse available in case of
            adverse trade outcomes. &nbsp;
          </span>
          <span className={styles.runningLine}>
            Pursuant to global financial regulations, the content provided on
            this website serves solely for general informational purposes. Trade
            only funds you are willing to risk losing entirely. Cryptoassets
            pose high risks, and there may be no recourse available in case of
            adverse trade outcomes. &nbsp;
          </span>
          <span className={styles.runningLine}>
            Pursuant to global financial regulations, the content provided on
            this website serves solely for general informational purposes. Trade
            only funds you are willing to risk losing entirely. Cryptoassets
            pose high risks, and there may be no recourse available in case of
            adverse trade outcomes. &nbsp;
          </span>
          <span className={styles.runningLine}>
            Pursuant to global financial regulations, the content provided on
            this website serves solely for general informational purposes. Trade
            only funds you are willing to risk losing entirely. Cryptoassets
            pose high risks, and there may be no recourse available in case of
            adverse trade outcomes. &nbsp;
          </span>
          <span className={styles.runningLine}>
            Pursuant to global financial regulations, the content provided on
            this website serves solely for general informational purposes. Trade
            only funds you are willing to risk losing entirely. Cryptoassets
            pose high risks, and there may be no recourse available in case of
            adverse trade outcomes. &nbsp;
          </span>
          <span className={styles.runningLine}>
            Pursuant to global financial regulations, the content provided on
            this website serves solely for general informational purposes. Trade
            only funds you are willing to risk losing entirely. Cryptoassets
            pose high risks, and there may be no recourse available in case of
            adverse trade outcomes. &nbsp;
          </span>
          <span className={styles.runningLine}>
            Pursuant to global financial regulations, the content provided on
            this website serves solely for general informational purposes. Trade
            only funds you are willing to risk losing entirely. Cryptoassets
            pose high risks, and there may be no recourse available in case of
            adverse trade outcomes. &nbsp;
          </span>
          <span className={styles.runningLine}>
            Pursuant to global financial regulations, the content provided on
            this website serves solely for general informational purposes. Trade
            only funds you are willing to risk losing entirely. Cryptoassets
            pose high risks, and there may be no recourse available in case of
            adverse trade outcomes. &nbsp;
          </span>
          <span className={styles.runningLine}>
            Pursuant to global financial regulations, the content provided on
            this website serves solely for general informational purposes. Trade
            only funds you are willing to risk losing entirely. Cryptoassets
            pose high risks, and there may be no recourse available in case of
            adverse trade outcomes. &nbsp;
          </span>
          <span className={styles.runningLine}>
            Pursuant to global financial regulations, the content provided on
            this website serves solely for general informational purposes. Trade
            only funds you are willing to risk losing entirely. Cryptoassets
            pose high risks, and there may be no recourse available in case of
            adverse trade outcomes. &nbsp;
          </span>
          <span className={styles.runningLine}>
            Pursuant to global financial regulations, the content provided on
            this website serves solely for general informational purposes. Trade
            only funds you are willing to risk losing entirely. Cryptoassets
            pose high risks, and there may be no recourse available in case of
            adverse trade outcomes. &nbsp;
          </span>
          <span className={styles.runningLine}>
            Pursuant to global financial regulations, the content provided on
            this website serves solely for general informational purposes. Trade
            only funds you are willing to risk losing entirely. Cryptoassets
            pose high risks, and there may be no recourse available in case of
            adverse trade outcomes. &nbsp;
          </span>
          <span className={styles.runningLine}>
            Pursuant to global financial regulations, the content provided on
            this website serves solely for general informational purposes. Trade
            only funds you are willing to risk losing entirely. Cryptoassets
            pose high risks, and there may be no recourse available in case of
            adverse trade outcomes. &nbsp;
          </span>
          <span className={styles.runningLine}>
            Pursuant to global financial regulations, the content provided on
            this website serves solely for general informational purposes. Trade
            only funds you are willing to risk losing entirely. Cryptoassets
            pose high risks, and there may be no recourse available in case of
            adverse trade outcomes. &nbsp;
          </span>
          <span className={styles.runningLine}>
            Pursuant to global financial regulations, the content provided on
            this website serves solely for general informational purposes. Trade
            only funds you are willing to risk losing entirely. Cryptoassets
            pose high risks, and there may be no recourse available in case of
            adverse trade outcomes. &nbsp;
          </span>
          <span className={styles.runningLine}>
            Pursuant to global financial regulations, the content provided on
            this website serves solely for general informational purposes. Trade
            only funds you are willing to risk losing entirely. Cryptoassets
            pose high risks, and there may be no recourse available in case of
            adverse trade outcomes. &nbsp;
          </span>
          <span className={styles.runningLine}>
            Pursuant to global financial regulations, the content provided on
            this website serves solely for general informational purposes. Trade
            only funds you are willing to risk losing entirely. Cryptoassets
            pose high risks, and there may be no recourse available in case of
            adverse trade outcomes. &nbsp;
          </span>
          <span className={styles.runningLine}>
            Pursuant to global financial regulations, the content provided on
            this website serves solely for general informational purposes. Trade
            only funds you are willing to risk losing entirely. Cryptoassets
            pose high risks, and there may be no recourse available in case of
            adverse trade outcomes. &nbsp;
          </span>
          <span className={styles.runningLine}>
            Pursuant to global financial regulations, the content provided on
            this website serves solely for general informational purposes. Trade
            only funds you are willing to risk losing entirely. Cryptoassets
            pose high risks, and there may be no recourse available in case of
            adverse trade outcomes. &nbsp;
          </span>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
}

export default Scroll;
