import { useState, useEffect, useRef } from "react";
import {
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Flex,
} from "@chakra-ui/react";
// import { useLocation } from "react-router-dom";

// import Home from "../Home";

function DisclaimerModal({ isOpen, onClose }) {
  const initialRef = useRef(null);
  //   const [modalOpened, setModalOpened] = useState(false);

  // const location = useLocation();
  //   useEffect(() => {
  //     if (location.state?.openModal && !modalOpened) {
  //       onOpen();
  //       setModalOpened(true);
  //     }
  //   }, [location, onOpen, modalOpened]);

  // const handleCloseModal = () => {
  //   console.log("closing");

  //   onClose();
  //   // setModalOpened(false);
  // };
  // if (location.pathname === "/disclaimer_trading") {
  //   onOpen();
  //   // setModalOpened(true);
  // }

  return (
    <>
      {/* <Home /> */}
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        initialFocusRef={initialRef}
      >
        <ModalOverlay bg={"rgba(0, 0, 0, 0.7)"} />
        <ModalContent
          bg="radial-gradient(73.86% 104.92% at 50.1% 14.77%, rgba(16, 24, 43, 1) 3.13%, rgba(15, 20, 32, 1) 100%)"
          color="white"
          paddingX={["10px,30px,30px,30px"]}
          paddingY={["20px"]}
          width="80%"
          maxW="700px"
        >
          <ModalHeader justifyContent={"center"} textAlign={"center"}>
            Trading Disclaimer
          </ModalHeader>

          <ModalBody opacity={1}>
            <div style={{ height: "50vh", overflowY: "scroll" }}>
              <>
                <p style={{ textAlign: "justify" }}>
                  <strong>
                    <span
                      style={{
                        color: "#ff0000",
                        backgroundColor: "transparent",
                        fontSize: "12pt",
                      }}
                    >
                      <br />
                      <br />
                    </span>
                  </strong>
                </p>
                <p style={{ textAlign: "justify" }}>
                  <strong>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        fontSize: "12pt",
                      }}
                    >
                      What are the main risks associated with acquiring $YAI
                      tokens and other crypto assets?
                    </span>
                  </strong>
                </p>
                <p>
                  <br />
                </p>
                <p style={{ textAlign: "justify" }}>
                  <span
                    style={{ backgroundColor: "transparent", fontSize: "12pt" }}
                  >
                    Getting involved in crypto assets can be thrilling, but it's
                    crucial to understand the risks, especially if you're new to
                    the scene. Here's what you should be aware of before
                    acquiring $YAI tokens or utilising Ÿ Project services:
                  </span>
                </p>
                <p>
                  <br />
                </p>
                <ol style={{ padding: "0px 20px", fontWeight: "bold" }}>
                  <li>
                    <ul>
                      <li
                        style={{ listStyleType: "decimal", fontSize: "12pt" }}
                      >
                        <p style={{ textAlign: "justify" }}>
                          <span
                            style={{
                              backgroundColor: "transparent",
                              fontSize: "12pt",
                            }}
                          >
                            The possibility of losing the entirety of your
                            trading capital is significant.
                          </span>
                        </p>
                      </li>

                      <li
                        style={{ listStyleType: "decimal", fontSize: "12pt" }}
                      >
                        <p style={{ textAlign: "justify" }}>
                          <span
                            style={{
                              backgroundColor: "transparent",
                              fontSize: "12pt",
                            }}
                          >
                            Selling your trading position may not be feasible at
                            your preferred time, leading to potential liquidity
                            issues.&nbsp;
                          </span>
                        </p>
                      </li>

                      <li
                        style={{ listStyleType: "decimal", fontSize: "12pt" }}
                      >
                        <p style={{ textAlign: "justify" }}>
                          <span
                            style={{
                              backgroundColor: "transparent",
                              fontSize: "12pt",
                            }}
                          >
                            You should not anticipate any protection in case of
                            adverse events or losses.
                          </span>
                        </p>
                      </li>

                      <li
                        style={{ listStyleType: "decimal", fontSize: "12pt" }}
                      >
                        <p style={{ textAlign: "justify" }}>
                          <span
                            style={{
                              backgroundColor: "transparent",
                              fontSize: "12pt",
                            }}
                          >
                            $YAI token is a pure utility token, not suitable for
                            speculative purposes. Its primary usage lies in
                            accessing the Ÿ Project ecosystem.
                          </span>
                        </p>
                      </li>

                      <li
                        style={{ listStyleType: "decimal", fontSize: "12pt" }}
                      >
                        <p style={{ textAlign: "justify" }}>
                          <span
                            style={{
                              color: "#ffffff",
                              backgroundColor: "transparent",
                              fontSize: "12pt",
                            }}
                          >
                            Be cautious of trusting Twitter Key Opinion Leaders
                            (KOLs) or market hype, as it can lead to emotional
                            and irrational decision-making.
                          </span>
                        </p>
                      </li>

                      <li
                        style={{
                          listStyleType: "decimal",
                          color: "#ffffff",
                          fontSize: "12pt",
                        }}
                      >
                        <p style={{ textAlign: "justify" }}>
                          <span
                            style={{
                              color: "#ffffff",
                              backgroundColor: "transparent",
                              fontSize: "12pt",
                            }}
                          >
                            The Ÿ Project emphasises that any recommendations,
                            suggestions, or inducements made by moderators or
                            other individuals in Telegram community chat do not
                            constitute official positions of the project. Users
                            are encouraged to verify information through the
                            official Ÿ Project channels, such as the official
                            Twitter account and website, for official
                            announcements, terms, plans, and other pertinent
                            details.
                          </span>
                        </p>
                      </li>
                    </ul>
                  </li>
                </ol>
                <p>
                  <br />
                </p>
                <p style={{ textAlign: "justify" }}>
                  <strong>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        fontSize: "12pt",
                      }}
                    >
                      Due to the next factors:
                    </span>
                  </strong>
                </p>
                <p>
                  <br />
                </p>
                <p style={{ textAlign: "justify" }}>
                  <em>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        fontSize: "12pt",
                      }}
                    >
                      Market Volatility
                    </span>
                  </em>
                  <span
                    style={{ backgroundColor: "transparent", fontSize: "12pt" }}
                  >
                    : Crypto prices can be like a rollercoaster ride. They can
                    shoot up to the moon one day and crash down to Earth the
                    next. These fluctuations often occur without clear reasons,
                    as a result of high volatility, posing risks for crypto
                    market participants.
                  </span>
                </p>
                <p>
                  <br />
                </p>
                <p style={{ textAlign: "justify" }}>
                  <em>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        fontSize: "12pt",
                      }}
                    >
                      Liquidity Challenge:
                    </span>
                  </em>
                  <span
                    style={{ backgroundColor: "transparent", fontSize: "12pt" }}
                  >
                    &nbsp;It may be difficult to find buyers for your crypto
                    assets when you want to sell, particularly during times of
                    market turbulence. This lack of liquidity can impede your
                    ability to exit positions swiftly. You should be prepared to
                    lose all the money you trade in crypto assets.
                  </span>
                </p>
                <p>
                  <br />
                </p>
                <p style={{ textAlign: "justify" }}>
                  <em>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        fontSize: "12pt",
                      }}
                    >
                      Security Threats:
                    </span>
                  </em>
                  <span
                    style={{ backgroundColor: "transparent", fontSize: "12pt" }}
                  >
                    &nbsp;Crypto attracts attention from cybercriminals due to
                    its digital nature. Hackers may exploit vulnerabilities in
                    exchanges, wallets, smart contracts or other platforms,
                    leading to the loss of assets.
                  </span>
                </p>
                <p>
                  <br />
                </p>
                <p style={{ textAlign: "justify" }}>
                  <em>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        fontSize: "12pt",
                      }}
                    >
                      Technological Risks:&nbsp;
                    </span>
                  </em>
                  <span
                    style={{ backgroundColor: "transparent", fontSize: "12pt" }}
                  >
                    Crypto transactions rely on complex technology, which isn't
                    immune to glitches, bugs, or hacking attempts. Such
                    technological failures can jeopardise your trading
                    positions.
                  </span>
                </p>
                <p>
                  <br />
                </p>
                <p style={{ textAlign: "justify" }}>
                  <em>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        fontSize: "12pt",
                      }}
                    >
                      Irreversible Transactions:
                    </span>
                  </em>
                  <span
                    style={{ backgroundColor: "transparent", fontSize: "12pt" }}
                  >
                    &nbsp;Once a crypto transaction is confirmed, it's usually
                    irreversible. Mistaken or fraudulent transactions may result
                    in permanent loss of funds.
                  </span>
                </p>
                <p>
                  <br />
                </p>
                <p style={{ textAlign: "justify" }}>
                  <em>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        fontSize: "12pt",
                      }}
                    >
                      Out of Control:
                    </span>
                  </em>
                  <span
                    style={{ backgroundColor: "transparent", fontSize: "12pt" }}
                  >
                    &nbsp;The Ÿ Project team provides services, however, they do
                    not assume a fiduciary duty. Users are solely responsible
                    for making informed decisions and managing their
                    investments.
                  </span>
                </p>
                <p>
                  <br />
                </p>
                <p style={{ textAlign: "justify" }}>
                  <em>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        fontSize: "12pt",
                      }}
                    >
                      Regulatory Uncertainty:
                    </span>
                  </em>
                  <span
                    style={{ backgroundColor: "transparent", fontSize: "12pt" }}
                  >
                    &nbsp;The regulatory landscape surrounding crypto assets is
                    continually evolving and varies across jurisdictions.
                    Changes in regulations could impact the legality and value
                    of crypto assets. What's legal today might not be tomorrow.
                  </span>
                </p>
                <p>
                  <br />
                </p>
                <p style={{ textAlign: "justify" }}>
                  <em>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        fontSize: "12pt",
                      }}
                    >
                      Market Manipulation and Unfair Marketing Techniques:
                    </span>
                  </em>
                  <span
                    style={{ backgroundColor: "transparent", fontSize: "12pt" }}
                  >
                    &nbsp;Crypto markets can be influenced by speculative
                    trading, pump-and-dump schemes, and social media hype. Ÿ
                    Project cannot control Twitter “marketing” or other forms of
                    market manipulation, posing risks to those acquiring $YAI
                    tokens.
                  </span>
                </p>
                <p>
                  <br />
                </p>
                <p style={{ textAlign: "justify" }}>
                  <em>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        fontSize: "12pt",
                      }}
                    >
                      Third-Party Risks:
                    </span>
                  </em>
                  <span
                    style={{ backgroundColor: "transparent", fontSize: "12pt" }}
                  >
                    &nbsp;Engaging with crypto assets often involves
                    interactions with third-party service providers, such as
                    exchanges or wallet providers. These entities may have their
                    terms and conditions, which could expose users to additional
                    risks.
                  </span>
                </p>
                <p>
                  <br />
                </p>
                <p style={{ textAlign: "justify" }}>
                  <em>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        fontSize: "12pt",
                      }}
                    >
                      FOMO and FUD:&nbsp;
                    </span>
                  </em>
                  <span
                    style={{ backgroundColor: "transparent", fontSize: "12pt" }}
                  >
                    Fear of missing out (FOMO) and fear, uncertainty, and doubt
                    (FUD) can mess with your head and lead to bad decisions.
                    Stay cool, calm, and collected.
                  </span>
                </p>
                <p>
                  <br />
                </p>
                <p style={{ textAlign: "justify" }}>
                  <span
                    style={{ backgroundColor: "transparent", fontSize: "12pt" }}
                  >
                    It's essential to remember that crypto trading carries
                    inherent risks, and the team behind a project cannot control
                    external market forces. Exercise caution, conduct thorough
                    research, and only use funds you can afford to lose.
                  </span>
                </p>
                <p>
                  <br />
                </p>
                <p>
                  <br />
                </p>
                <p>
                  <br />
                </p>
              </>
            </div>
          </ModalBody>

          <ModalFooter justifyContent="flex-start">
            <Flex justifyContent="flex-start">
              <Button
                variant={"solid"}
                // onClose={onClose}
                onClick={onClose}
                // onClick={handleAccept}
                outline={"none"}
                boxShadow={"none !important"}
              >
                Close
              </Button>
              {/* <Button
                ml={2}
                colorScheme="transparent"
                outline={"none"}
                boxShadow={"none !important"}
                mr={3}
                // onClick={handleReject}
              >
                Reject
              </Button> */}
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default DisclaimerModal;
