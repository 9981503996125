// import React from "react";
import React, { useRef, useState } from "react";

import styles from "./Why.module.css";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import "./slider.css";
import { Pagination, Autoplay } from "swiper/modules";

export default function Why() {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.subContainer}>
        <div className={styles.headingContainer}>
          <div className={styles.mainHeading}>As simple as 'Why'</div>

          <div className={styles.subHeading}>
            Get access to Oracle through multiple ways.
          </div>
        </div>

        <div
         
          className={styles.cardContainer}
        >
          <div className={styles.card}>
            {/* <div className={styles.overlay}> */}
            <div className={styles.cardImage}>
              {/* <div className={styles.overlayContent}></div> */}

              <img src="/images/newsCard.png" />
            </div>
            {/* </div> */}
            {/* <div className={styles.cardImage}>
              <img src="/images/newsCard.png" />
            </div> */}
            <div className={styles.cardTextContainer}>
              <div className={styles.cardTitle}>News squawk</div>
              <div className={styles.cardDesc}>
                Real time aggregated news feed and impact predictions for each. 
              </div>
            </div>
          </div>

          <div className={styles.card}>
            <div className={styles.cardImage}>
              <img src="/images/chatCard.png" />
            </div>
            <div className={styles.cardTextContainer}>
              <div className={styles.cardTitle}>Chat with Oracle</div>
              <div className={styles.cardDesc}>
                Chat with Oracle, ask it questions about the current market
                situation, edge or alpha.
              </div>
            </div>
          </div>

          <div className={styles.card}>
            <div className={styles.cardImage}>
              <img src="/images/browseCard.png" />
            </div>
            <div className={styles.cardTextContainer}>
              <div className={styles.cardTitle}>Self-Browse reports</div>
              <div className={styles.cardDesc}>
                Intraday sentiment reports covering updates on all our news &
                sentiment data. Oracle communicates on its own. Full market
                coverage
              </div>
            </div>
          </div>
          <div className={styles.sliderCont}>
            <Swiper
              slidesPerView={1}
              // spaceBetween={0}
              autoplay={{
                delay: 3500,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination, Autoplay]}
            >
              <SwiperSlide>
                <div className={styles.Mcard}>
                  <div className={styles.McardImage}>
                    <img src="/images/newsCard.png" />
                  </div>
                  <div className={styles.McardTextContainer}>
                    <div className={styles.McardTitle}>News squawk</div>
                    <div className={styles.McardDesc}>
                      Real time aggregated news feed and impact predictions for
                      each. 
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={styles.Mcard}>
                  <div className={styles.McardImage}>
                    <img src="/images/chatCard.png" />
                  </div>
                  <div className={styles.McardTextContainer}>
                    <div className={styles.McardTitle}>Chat with Oracle</div>
                    <div className={styles.McardDesc}>
                      Chat with Oracle, ask it questions about the current
                      market situation, edge or alpha.
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={styles.Mcard}>
                  <div className={styles.McardImage}>
                    <img src="/images/browseCard.png" />
                  </div>
                  <div className={styles.McardTextContainer}>
                    <div className={styles.McardTitle}>Self-Browse reports</div>
                    <div className={styles.McardDesc}>
                      Intraday sentiment reports covering updates on all our
                      news & sentiment data. Oracle communicates on its own.
                      Full market coverage.
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
}
