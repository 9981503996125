import React from "react";
import styles from "./Hero.module.css";
import { Link } from "react-router-dom";

export default function Hero() {

  
  return (
    <div className={styles.mainContainer}>
      <div className={styles.subContainer}>
        <div className={styles.LogoContainer}>
          <img src="/logo.png" style={{height:"2rem"}} />
        </div>
        <div
        
          className={styles.textContainer}
        >
          <div className={styles.animationContainer}>
            <div className={styles.animation}>
              {" "}
              {/* <img src="/images/GreenIcon.png" /> */}
            </div>
            <div className={styles.light}></div>
          </div>
          <div className={styles.topText}>
            predicting future patterns as an AI service
          </div>
          <div className={styles.centerText}>
            AI-powered market data oracle.
          </div>
          <div className={styles.subText}>
            Empowering users with cutting-edge AI models for unmatched,
            real-time market analytics & insights.
          </div>
          <Link to="" className={styles.button}>
            {/* <div className={styles.button}> */}
            Try now
            {/* </div> */}
          </Link>
        </div>

        {/* <div className={styles.bottomContainer}>
          <div className={styles.bmainTextCont}>
            <span className={styles.bmainText}>EYE AI</span>
            <span className={styles.stop}>_</span>
          </div>
          <div className={styles.bsubText}>
            Oracle connected to all market data. Predict impact of events.
            Predict price moves and narratives. Predict chain rotations and
            airdrop events. No limits.
          </div>
        </div> */}
      </div>
    </div>
  );
}
