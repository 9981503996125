import React from "react";
import styles from "./Network.module.css";
import { Link } from "react-router-dom";

function Network() {
  return (
    <div className={styles.mainContainer}>
      <img className={styles.badge} src="/images/badge.png" />
      <img className={styles.badgeM} src="/images/badgeM.png" />

      <div className={styles.subContainer}>
        <div className={styles.headingContainer}>
          <div className={styles.mainHeading}>$EYEAI Token Network: ETH</div>

          <div className={styles.bottomContainer}>
            <div className={styles.Cta}>
              <div className={styles.title}>User access</div>
              <div className={styles.desc}>
                Available as hold to use & paid access models. Revenue share.
              </div>
              <Link to={""} className={styles.button1}>
                Get Access
              </Link>
              {/* <div className={styles.button1}>Get Access</div> */}
            </div>

            <div className={styles.Cta}>
              <div className={styles.title}>Customer</div>
              <div className={styles.desc}>
                Enterprise Grade solutions to fit your company's data needs.
              </div>
              {/* <div className={styles.button2}> */}
              <Link
                to={""}
                className={styles.button2}
              >
                Contact Us
              </Link>
              {/* </div> */}

              {/* <div className={styles.button2}>Contact Us</div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Network;
