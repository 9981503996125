import React from "react";
import styles from "./Database.module.css";

function Database() {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.subContainer}>
        <div
         
          className={styles.leftContainer}
        >
          <img className={styles.imageD} src="/images/database.png" />
          <img className={styles.imageM} src="/images/databaseM.png" />
        </div>

        <div
     
          className={styles.rightContainer}
        >
          <div className={styles.text}>Rich & Ever growing Oracle database</div>
        </div>
      </div>
    </div>
  );
}

export default Database;
