import React, { useState, useEffect, useRef } from "react";
import Hero from "./Hero/Hero";
import styles from "./Home.module.css";
import AIoracle from "./AIoracle/AIoracle";
import Observers from "./Observers/Observers";
import Why from "./Why/Why";
import How from "./How/How";
import Database from "./Database/Database";
import Network from "./Network/Network";
import Join from "./Join/Join";
import Allinone from "./Allinone/Allinone";
import Modal from "./Modal";
import AOS from "aos";
import "aos/dist/aos.css";
import LocationModal from "./LocationModal";
import Scroll from "./Scroll/Scroll";
import DisclaimerModal from "./DisclaimerModal/DisclaimerModal";
import { useDisclosure } from "@chakra-ui/react";
import { useNavigate, useLocation } from "react-router-dom";

function Home() {
  const joinRef = useRef(null);
  const [isJoinVisible, setIsJoinVisible] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // ... AOS initialization ...

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsJoinVisible(true);
          observer.disconnect(); // Disconnect the observer after it runs once
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );

    if (joinRef.current) {
      observer.observe(joinRef.current);
    }

    return () => {
      // Ensure the observer is disconnected when the component unmounts
      observer.disconnect(); 
    };
  }, []);

  // useEffect(() => {
  //   if (location.pathname === "/disclaimer_trading") {
  //     onOpenDisclaimerModal();
  //     return;
  //   }
  //   onCloseDisclaimerModal();
  // }, [location]);

  const handleNavigate = () => {
    navigate("/");
  };

  const {
    isOpen: isOpenDisclaimerModal,
    onOpen: onOpenDisclaimerModal,
    onClose: onCloseDisclaimerModal,
  } = useDisclosure();

  return (
    <div className={styles.mainContainer}>
      <div className={styles.animationContainerT}>
        <div className={styles.animationT}> </div>
        <div className={styles.lightT}></div>
      </div>

      <Hero />
      <AIoracle />
      {/* <Modal /> */}
      {/* <LocationModal /> */}
      <Scroll />
      <Observers />
      <Why />
      <How />
      <Allinone />

      <Database />
      <DisclaimerModal
        isOpen={isOpenDisclaimerModal}
        onClose={handleNavigate}
      />

      <div className={styles.joinBg}>
        <div ref={joinRef} className={styles.animationContainer}>
          {isJoinVisible && (
            <>
              <div className={styles.animation}> </div>
              <div className={styles.light}></div>
            </>
          )}
        </div>
        <Network />

        <Join />
        {/* <img src="/images/joinBg.png" /> */}
      </div>
      {/* <div className={styles.modal}> */}
      {/* </div> */}
    </div>
  );
}

export default Home;
