import React from "react";
import styles from "./How.module.css";

export default function How() {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.subContainer}>
        <div className={styles.GradientL}></div>
        <div className={styles.GradientR}></div>
        <div className={styles.textContainer}>
          <div className={styles.logoContainer}>
            <img src="/logo.png" style={{ height: "2rem" }} />
          </div>
          <div className={styles.mainHeading}>As complete as ‘How’</div>
          <div className={styles.subHeading}>
            How our Observer and Predictor systems interact
          </div>
        </div>
        <img className={styles.bgD} src="/images/howBG.png" />

        <img className={styles.bgM} src="/images/howBGMT2.svg" />
      </div>
    </div>
  );
}
