import React, { useState, useRef } from "react";
import styles from "./Allinone.module.css";

const faqs = [
  {
    id: 1,
    header: "Real time news feed",
    subHeading: "News impact predictions",
    text: `Keep track of all important news headlines in the market. Our models are trained to provide impact predictions for each news headline.`,
  },
  {
    id: 2,
    header: "Social activity impact",
    subHeading: "Social activity impact",

    text: `Cross social platform activity trackers. Real time analytics for social activity. `,
  },
  {
    id: 3,
    header: "Intraday sentiment reports",
    subHeading: "Intraday sentiment reports",

    text: `Timed sentiment reports based on all recently gathered data. 
`,
  },
  {
    id: 4,
    header: "Trend Recognition",
    subHeading: "Trend Recognition",

    text: `Predictors are trained to recognise trends and stay up to date with the current trends in the market. 
`,
  },

  {
    id: 5,
    header: "Full Market Coverage",
    subHeading: "Full Market Coverage",

    text: `We strive to one day reach full market data coverage. More tools will be announced soon. `,
  },
];
const Accordion = ({ faq, isOpen, onClick }) => {
  return (
    <div className={styles.accordionContainer}>
      <h4 onClick={onClick} className={isOpen ? styles.active : ""}>
        {faq.header}{" "}
        <span className={`${styles.arrow} ${isOpen ? styles.down : ""}`}>
          <img className={styles.arrowStyle} src="/images/arrowDown.svg" />
        </span>
      </h4>
      <div className={`${styles.content} ${isOpen ? styles.show : ""}`}>
        <div className={styles.subHeading}>{faq.subHeading}</div>

        <p>{faq.text}</p>
      </div>
    </div>
  );
};

export default function Allinone() {
  const [openFaqId, setOpenFaqId] = useState(null);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.subContainer}>
        <div className={styles.mainHeading}>
          Massive intelligence input. <br /> One output.
          <span> All-in-one.</span>
        </div>
        <div className={styles.itemContainer}>
          <div className={styles.leftContainer}>
            {/* <h2>React Accordion</h2> */}
            {faqs.map((faq) => (
              <Accordion
                key={faq.id}
                faq={faq}
                isOpen={faq.id === openFaqId}
                onClick={() =>
                  setOpenFaqId(faq.id !== openFaqId ? faq.id : null)
                }
              />
            ))}
          </div>

          <div className={styles.rightContainer}>
            <img src="/images/newsFeed.png" />
          </div>
        </div>
      </div>
    </div>
  );
}
