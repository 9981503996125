import React, { useEffect, useState } from "react";
import axios from "axios";

// const useIPDetector = () => {
//   const [ipUs, setIpUs] = useState(false);

//   const handleOnClick = () => {
//     setIpUs(false);
//   };

//   useEffect(() => {
//     const fetchIPData = async () => {
//       try {
//         const response = await axios.get(
//           "https://api.bigdatacloud.net/data/country-by-ip?key=bdc_21eef78005a64108b630aeed39994a68"
//         );
//         setIpUs(
//           ["US","CN", "AE"].includes(response.data.country.isoAlpha2)
//         );
//       } catch (error) {
//         console.error("Error fetching IP data:", error);
//       }
//     };

//     fetchIPData();
//   }, []);

//   return { ipUs, onClick: handleOnClick };
// };

const useIPDetector = () => {};
export default useIPDetector;
