import React, { useState, useEffect, useRef } from "react";
import styles from "./Join.module.css";
import { Link } from "react-router-dom";

export default function Join() {
  const joinRef = useRef(null);
  const [isJoinVisible, setIsJoinVisible] = useState(false);

  useEffect(() => {
    // ... AOS initialization ...

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsJoinVisible(true);
          observer.disconnect(); // Disconnect the observer after it runs once
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );

    if (joinRef.current) {
      observer.observe(joinRef.current);
    }

    return () => {
      observer.disconnect(); // Ensure the observer is disconnected when the component unmounts
    };
  }, []);

  return (
    <div className={styles.mainContainer}>
      {/* <img className={styles.bg} src="/images/joinBg.png" /> */}
      <div className={styles.subContainer}>
        {/* <div className={styles.textContainer}> */}
        <div className={styles.centerText}>
          <div ref={joinRef} className={styles.animationContainer}>
            {isJoinVisible && (
              <>
                <div className={styles.animation}> </div>
                <div className={styles.light}></div>
              </>
            )}
          </div>
          PREDICT THE FUTURE NOW
        </div>
        <div className={styles.buttonContainer}>
          <Link to={""} className={styles.button1}>
            Get Access
          </Link>
          {/* <div className={styles.button1}>Get Access</div> */}

          {/* <div className={styles.button2}> */}
          <Link to={""} className={styles.button2}>
            Contact Us
          </Link>
          {/* </div> */}
        </div>
        <div className={styles.LogoContainer}>
          <img src="/logo.png" style={{ height: "2rem" }} />
        </div>

        <div className={styles.linkContainer}>
          <Link to="" className={styles.linkItem}>
            Twitter
          </Link>
          <Link to="" className={styles.linkItem}>
            Telegram
          </Link>
          <Link to="" className={styles.linkItem}>
            Documentation
          </Link>
          <Link to="/legal" className={styles.linkItem}>
            Legal
          </Link>
          <Link to="" className={styles.linkItem}>
            API
          </Link>
        </div>
        <div className={styles.contractAddress}>
          CA: 0x477a3d269266994F15E9C43A8D9C0561C4928088
        </div>
        <div className={styles.copyright}>
          Copyright © Y 2024. All rights reserved
        </div>
        {/* </div> */}
      </div>
    </div>
  );
}
