import React from "react";
import styles from "./Observers.module.css";
import { Link } from "react-router-dom";

export default function Observers() {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.subContainer}>
        <div className={styles.heading}>oBSERVERS & PREDICTORS</div>
        <div className={styles.subheading}>
          Revolutionary custom-tuned AI & ML model
        </div>

        <div
   
          className={styles.cardContainer}
        >
          <Link to={""} className={styles.card1}>
            {/* <div className={styles.card1}> */}
            {/* <div> */}
            <img src="/images/whiteArrow.svg" className={styles.arrow} />
            {/* </div> */}
            <img className={styles.cardImage} src="/images/observerImage.png" />
            <div className={styles.cardTextContainer}>
              <div className={styles.cardTitle1}>Observers</div>
              <div className={styles.cardDesc1}>
                The listeners. On watch for all information sources, news,
                market prices, liquidity information, on-chain and off-chain
                price moves, market events and upcoming events. 
              </div>
            </div>
            {/* </div> */}
          </Link>

          <Link to={""} className={styles.card2}>
            {/* <div className={styles.card2}> */}
            {/* <Link className={styles.arrow} to={""}> */}
            <img src="/images/blackArrow.svg" className={styles.arrow} />
            {/* </Link> */}
            <img
              className={styles.cardImage}
              src="/images/predictorImage.png"
            />
            <div className={styles.cardTextContainer}>
              <div className={styles.cardTitle2}>Predictors</div>
              <div className={styles.cardDesc2}>
                The workers. After receiving a request, news, alert or update,
                our proprietary models label, analyze and score the received
                data.
              </div>
            </div>
            {/* </div> */}
          </Link>
        </div>
      </div>
    </div>
  );
}
