import React, { useState, useEffect, useRef } from 'react';
import styles from './AIoracle.module.css';
function AIoracle() {
  const joinRef = useRef(null);
  const [isJoinVisible, setIsJoinVisible] = useState(false);

  useEffect(() => {

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsJoinVisible(true);
          observer.disconnect(); // Disconnect the observer after it runs once
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.3,
      }
    );

    

    if (joinRef.current) {
      observer.observe(joinRef.current);
    }

    return () => {
      observer.disconnect(); // Ensure the observer is disconnected when the component unmounts
    };
  }, []);
  return (
    <div className={styles.mainContainer}>
      <div className={styles.subContainer}>
        <div className={styles.textContainer}>
          <div className={styles.mainTextCont}>
            <span className={styles.mainText}>EYE AI</span>
            <span className={styles.stop}>_</span>
          </div>
          <div className={styles.subText}>
            Oracle connected to all market data. Predict impact of events. Predict price moves and narratives. Predict chain rotations and airdrop
            events. No limits.
          </div>
        </div>

        <div className={styles.bottomContainer}>
          <div ref={joinRef} className={styles.imageContainer}>
            {isJoinVisible && (
              <>
                <div className={`${styles.imageItem1}   ${styles.imageItem}`}>Edge</div>
                <div className={`${styles.imageItem2}   ${styles.imageItem}`}>Orderflow</div>
                <div className={`${styles.imageItem3}   ${styles.imageItem}`}>Micro</div>
                <div className={`${styles.imageItem4}   ${styles.imageItem}`}>Bull</div>
                <div className={`${styles.imageItem5}   ${styles.imageItem}`}>Volume</div>
                <div className={`${styles.imageItem6}   ${styles.imageItem}`}>News</div>
                <div className={`${styles.imageItem7}   ${styles.imageItem}`}>Data</div>
                <div className={`${styles.imageItem8}   ${styles.imageItem}`}>Prediction</div>
                <div className={`${styles.imageItem9}   ${styles.imageItem}`}>Alpha</div>
                <div className={`${styles.imageItem10}   ${styles.imageItem}`}>Level</div>
                <div className={`${styles.imageItem11}   ${styles.imageItem}`}>Macro</div>
                <div className={`${styles.imageItem12}   ${styles.imageItem}`}>Future</div>
              </>
            )}

            <img src='/images/brainT.png' />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AIoracle;
